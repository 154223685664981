import { ImageLoaderConfig } from '@angular/common';
import { ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { Category, checkIsProduct, config, Product } from '@manzuko/shared';
import { productPath } from '@manzuko/shared/utils/product.helpers';
import { environment } from 'environments/environment';
import { Response } from 'express';
import { JsonLdDocument } from 'jsonld';

import { categoryPath } from '../category/category.helpers';
import { handleHttpStatus } from '../utils';

export const productUrlMatcher = (url: UrlSegment[]) => {
  // valid urls:
  // /17250-klej-f-6000-precyzyjne-klejenie-srednia-tuba-bezbarwny-transparentny-50ml-1szt.html
  // /narzedzia-jubilerskie/17250-klej-f-6000-precyzyjne-klejenie-srednia-tuba-bezbarwny-transparentny-50ml-1szt.html

  if (!url.length) {
    return null;
  }

  if (!checkIsProduct(url)) {
    return null;
  }
  const lastUrl = url[url.length - 1];
  const productId = new UrlSegment(lastUrl?.path.match(/^(\d+)/gm)[0], {});
  return {
    consumed: url,
    posParams: { productId }
  };
};

export const productCdnUrl = (
  path: string,
  { src, width, loaderParams }: ImageLoaderConfig
): string => {
  const url = new URL(`${path}/assets/images/p/${src}.jpg`);
  // This setting ensures the smallest allowable format is set.
  // url.searchParams.set('type', 'auto');
  if (width) {
    url.searchParams.set('width', width?.toString());
  }
  // url.searchParams.set('quality', '80');
  // url.searchParams.set('file', `/p/${src}.jpg`);
  return url.href;
};

export const productImageUrl = (
  { id, imageId, slug }: Partial<Product> = {},
  size?: 'cart' | 'home' | 'large'
): string => {
  const image = size ? `-${size}_default` : '';
  if (imageId) {
    return `${environment.cdn}/assets/images/p/${imageId}.jpg`;
    // return id && `${environment.cdn}/${imageId}${image}/${slug || 'product'}`;
    return (
      id && `${environment.cdn}?width=250&height=250&type=auto&quality=60&file=/p/${imageId}.jpg`
    );
    // https://manzuko.b-cdn.net/assets/images/p/62175.jpg
    // https://manzuko.live/resize?width=250&height=250&type=auto&file=%2Fp%2F62175.jpg
  }
  return `${environment.api}/products/${id}/image`;
};

export const productSeoUrl = (product: Partial<Product> = {}, lang?: string): string => {
  return `${config.website.url}${productPath(product, lang)}`;
};

export const productWithUrls = (product: Product, lang?: string): Product => {
  return {
    ...product,
    path: productPath(product, lang),
    url: productSeoUrl(product, lang),
    urlPl: productSeoUrl(product, 'pl'),
    urlEn: productSeoUrl(product, 'en')
  };
};

export const productJsonLd = (
  product: Partial<Product> = {},
  category: Partial<Category> = {}
): JsonLdDocument => {
  const productID = product?.id.toString();
  const description = product?.metaDescription || product?.description;
  const sku = product?.reference;
  const name = product?.name;
  const brand = { '@type': 'Brand', name: config?.website?.name };

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    brand,
    image: productImageUrl(product, 'large'),
    offers: {
      '@type': 'Offer',
      priceCurrency: 'PLN',
      price: product?.price,
      availability: 'https://schema.org/InStock',
      itemCondition: 'https://schema.org/UsedCondition',
      url: productSeoUrl(product),
      image: productImageUrl(product, 'large'),
      name,
      sku,
      mpn: sku,
      seller: {
        '@type': 'Organization',
        brand
      },
      hasMerchantReturnPolicy: {
        '@type': 'MerchantReturnPolicy',
        applicableCountry: 'PL',
        returnPolicyCategory: 'MerchantReturnFiniteReturnWindow',
        merchantReturnDays: config.merchantReturnDays,
        returnMethod: 'ReturnByMail',
        returnFees: 'ReturnShippingFees'
      },
      shippingDetails: {
        '@type': 'OfferShippingDetails',
        shippingRate: config.shippingRate,
        shippingDestination: {
          '@type': 'DefinedRegion',
          addressCountry: config.organization.address.addressCountry
        },
        deliveryTime: config.deliveryTime
      }
    },
    description,
    productID,
    sku,
    mpn: sku,
    category: category?.name
  };
};

export const productCanonicalRedirect = (
  product: Partial<Product>,
  route: ActivatedRouteSnapshot,
  platformId: Object,
  response: Response,
  router: Router,
  lang: string = 'pl'
): void => {
  const canonicalUrl = productPath(product, lang);
  const currentUrl = `/${route?.url?.map((url) => url?.path)?.join('/')}`;
  if (
    canonicalUrl?.toLocaleLowerCase() === currentUrl?.toLocaleLowerCase() &&
    route.params?.lang !== 'en'
  ) {
    return;
  }
  handleHttpStatus({ httpStatus: 302, httpRedirect: canonicalUrl }, platformId, response, router);
};

export const productRemovedRedirect = (
  product: Partial<Product>,
  platformId: Object,
  response: Response,
  router: Router,
  lang: string = 'pl'
): void => {
  if (!product.removed) {
    return;
  }

  const categoryUrl = categoryPath(
    {
      id: product.categoryId,
      slug: product.categorySlug,
      slugPl: product.categorySlugPl,
      slugEn: product.categorySlugEn
    },
    lang
  );
  handleHttpStatus({ httpStatus: 302, httpRedirect: categoryUrl }, platformId, response, router);
};
