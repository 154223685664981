<router-outlet />

<!-- @if (googleTagRouter$ | async) {} -->
@if (gaConsent$ | async) {}
@if (log$ | async) {}
@if (check$ | async) {}
@if (prompt$ | async) {}
@if (recover$ | async) {}
<!-- @if (lang$ | async) {} -->
@if (trustedshops$ | async) {}
@if (getFavorites$ | async) {}
