<header class="bg-white" *transloco="let t">
  <div
    data-test="header-logo"
    class="p-2 flex flex-row justify-between items-center sm:px-4 md:px-6">
    <button
      type="button"
      title="Menu"
      class="md:hidden mr-1 text-neutral-500"
      (click)="toggleMenu()">
      <svg
        alt="Menu"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-10 h-10">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
      </svg>
    </button>
    <a class="max-md:hidden" [routerLink]="'/' | mzkLink | async" title="Strona główna - Manzuko">
      <img
        class="w-20 h-[38px] md:w-40 md:h-[76px]"
        src="https://manzuko.b-cdn.net/assets/images/logo/logo-text.svg"
        loading="eager"
        fetchpriority="high"
        alt="Sklep z Koralikami i Akcesoriami do Wyrobu Biżuterii - Półfabrykatami - Manzuko" />
    </a>
    <mzk-search data-test="search" class="min-h-11" />
    <div id="userbar" class="bg-white pt-4 pb-3 z-[101]">
      <ul class="flex justify-evenly items-center text-center text-brand-700 text-sm">
        <!-- User Menu -->
        <li class="relative sm:min-w-18" data-test="header-user">
          <mzk-customer-icon class="md:mx-3" />
        </li>
        <li
          data-test="header-favourite"
          [routerLink]="'/konto/ulubione' | mzkLink | async"
          class="relative min-w-18 max-md:hidden">
          <fast-svg name="favorite" />
          <span>
            {{ t('shop.header.favourite') }}
          </span>
        </li>
        <li data-test="header-cart" class="min-w-18 max-md:hidden">
          <mzk-cart-icon />
        </li>
      </ul>
    </div>
  </div>

  <ng-content />
</header>
