import { isPlatformServer } from '@angular/common';

import { categoryWithUrls } from './category/category.helpers';

import type { Category, Product } from '@manzuko/shared';
import type { Response } from 'express';
import type { Router } from '@angular/router';

export const getBreadcrumbs = (categories, currentCategory, language) => {
  const breadcrumbs = [];
  let current = currentCategory;
  breadcrumbs.push({
    ...categoryWithUrls(current, language)
  });
  while (current?.parentId > 2) {
    current = categoryWithUrls(
      categories?.find((c) => c?.id === current.parentId),
      language
    );
    breadcrumbs.push({ ...current });
  }
  breadcrumbs.push({
    displayName: 'Manzuko',
    name: 'Manzuko',
    path: '/'
  });
  return breadcrumbs.reverse();
};

type HttpItem = Partial<Product | Category> & { httpStatus?: number; httpRedirect?: string };

export const handleHttpStatus = (
  item: HttpItem,
  platformId: Object,
  response: Response,
  router: Router
) => {
  // console.log(item);

  if ((!item || item?.httpStatus === 404) && isPlatformServer(platformId)) {
    return response.status(404);
  }

  if (item?.httpStatus === 301 || item?.httpStatus === 302) {
    if (isPlatformServer(platformId) && response) {
      return response.redirect(item?.httpStatus, item?.httpRedirect);
    }
    return router.navigate([item?.httpRedirect]);
  }
};
