@if (banners(); as banners) {
  <div class="w-full flex flex-col lg:space-x-1 lg:flex-row">
    <!-- items-end pr-[5%] -->
    @for (banner of banners; track banner.id) {
      <a
        [routerLink]="banner?.url | mzkLink | async"
        class="flex flex-col w-full h-20 relative justify-center items-center sm:h-[150px] md:h-[200px]">
        <img
          class="w-full object-cover"
          [ngSrc]="banner?.imagePath"
          [alt]="banner?.locale?.[lang()]?.title || ''"
          [sizes]="
            '(max-width: 959px) 100vw, (min-width: 960px) ' + (100 / banners?.length - 1) + 'vw'
          "
          fill
          priority />
        @if (banner?.locale?.pl?.title) {
          <div
            class="flex flex-col w-full h-20 relative justify-center items-center mb-0.5 md:mb-1 sm:h-[150px] md:h-[200px]">
            <div
              class="flex w-auto max-w-[97%] m-auto bg-white bg-opacity-70 text-center leading-5 flex-row items-center space-y-2 backdrop-blur-[1px] rounded-sm md:px-5 md:space-x-5 text-xl sm:text-3xl md:text-5xl sm:p-4 sm:space-y-0 sm:leading-7 md:p-8 md:leading-10">
              <div
                class="flex flex-col py-2 px-4 space-y-1 md:flex-row md:space-y-0 md:space-x-5 md:leading-10">
                <h6 class="font-semibold uppercase">
                  {{ banner?.locale?.pl?.title }}
                </h6>
                <strong class="font-semibold text-3xl md:text-7xl">
                  {{ banner?.discount }}
                </strong>
              </div>
              <div class="flex flex-col py-2 px-4 md:flex-row md:space-x-5 md:leading-10">
                <p class="text-md sm:text-xl md:text-3xl">
                  @if (banner?.showDate) {
                    {{ banner?.showDate }}
                  }
                </p>
                <p class="text-sm sm:text-xl md:text-2xl">
                  {{ banner?.locale?.pl?.button || 'Sprawdź produkty' }}
                </p>
              </div>
            </div>
          </div>
        } @else {
          <div
            class="flex flex-col w-full h-20 relative justify-center items-center mb-0.5 md:mb-1 sm:h-[150px] md:h-[200px]"
            [innerHTML]="banner?.locale?.[lang()]?.description"></div>
        }
      </a>
    }
  </div>
}
