<form
  [formGroup]="cookiesForm"
  *transloco="let t"
  class="p-3 -inset-x-1/2 bg-white rounded-lg fixed"
  [ngClass]="
    showAdvanced
      ? 'min-h-1/3 w-4/5 sm:w-3/5 m-auto top-22 sm:top-40 bottom-40 sm:bottom-16 overflow-auto'
      : 'w-4/5 sm:w-120 m-auto sm:m-0 sm:left-22 bottom-20 sm:bottom-16'
  ">
  <div class="mb-2 flex flex-row justify-between items-center">
    <h2 class="text-xl font-semibold">
      {{ t('cookies.title') }}
    </h2>
    @if (showAdvanced) {
      <button
        type="button"
        class="rounded-2xl inline-flex items-center leading-none text-black"
        (click)="toggleAdvanced(!showAdvanced)">
        <fast-svg name="cookie-decline" size="14" />
      </button>
    }
  </div>
  <p class="mb-4 leading-relaxed text-black">
    {{ t('cookies.info') }}
    <a class="font-semibold text-blue-700 hover:underline" routerLink="/polityka-prywatnosci">
      {{ t('cookies.policy') }}
    </a>
    .
  </p>

  @if (showAdvanced) {
    <div class="transition duration-700 ease-in-out">
      <p class="mb-2">{{ t('cookies.advanced') }}</p>
      <p class="mb-2">{{ t('cookies.essential') }}</p>
      <p class="mb-2">{{ t('cookies.other') }}</p>
      <p class="mb-6">{{ t('cookies.options') }}</p>
      <div class="border-t-2 mb-2 flex flex-row justify-between items-center">
        <p class="text-2xl font-bold">{{ t('cookies.necessary') }}</p>
        <p class="font-bold text-green-600">{{ t('cookies.alwaysActive') }}</p>
      </div>
      <p class="mt-2 mb-4">{{ t('cookies.necessaryText') }}</p>
      <div class="border-t-2 mb-2 flex flex-row justify-between items-center">
        <p class="text-2xl font-bold">{{ t('cookies.functional') }}</p>
        <input
          type="checkbox"
          formControlName="functional"
          class="w-5 h-5 mt-[1px] rounded text-brand" />
      </div>
      <p class="mt-2 mb-4">{{ t('cookies.functionalText') }}</p>
      <div class="border-t-2 mb-2 flex flex-row justify-between items-center">
        <p class="text-2xl font-bold">{{ t('cookies.analytics') }}</p>
        <input
          type="checkbox"
          formControlName="analytics"
          class="w-5 h-5 mt-[1px] rounded text-brand" />
      </div>
      <p class="mt-2 mb-4">{{ t('cookies.analyticsText') }}</p>
      <div class="border-t-2 mb-2 flex flex-row justify-between items-center">
        <p class="text-2xl font-bold">{{ t('cookies.efficiency') }}</p>
        <input
          type="checkbox"
          formControlName="performance"
          class="w-5 h-5 mt-[1px] rounded text-brand" />
      </div>
      <p class="mt-2 mb-4">{{ t('cookies.efficiencyText') }}</p>
      <div class="border-t-2 mb-2 flex flex-row justify-between items-center">
        <p class="text-2xl font-bold">{{ t('cookies.advertising') }}</p>
        <input
          type="checkbox"
          formControlName="advertising"
          class="w-5 h-5 mt-[1px] rounded text-brand" />
      </div>
      <p class="mt-2 mb-4">{{ t('cookies.advertisingText') }}</p>
      <div class="border-t-2 mb-2 flex flex-row justify-between items-center">
        <p class="text-2xl font-bold">{{ t('cookies.others') }}</p>
        <input
          type="checkbox"
          formControlName="others"
          class="w-5 h-5 mt-[1px] rounded text-brand" />
      </div>
      <p class="mt-2 mb-2">{{ t('cookies.othersText') }}</p>
    </div>
  }

  <div
    class="flex flex-col sm:flex-row max-sm:items-end"
    [ngClass]="showAdvanced ? 'justify-end' : 'justify-between sm:justify-end'">
    <button
      type="submit"
      class="max-sm:mb-2 max-w-32 rounded-xl inline-flex items-center px-2 py-2 mr-2 leading-none text-white bg-green-800 hover:bg-green-900"
      data-test="cookie-accept-button"
      (click)="hideCookies(cookiesForm?.value)">
      <fast-svg name="cookie-accept" size="18" />
      {{ t('cookies.accept') }}
    </button>

    @if (!showAdvanced) {
      <button
        type="button"
        class="rounded-xl max-w-32 inline-flex items-center px-2 py-2 leading-none text-white bg-gray-700 hover:bg-gray-800"
        (click)="toggleAdvanced(!showAdvanced)">
        <fast-svg name="cookie-settings" size="18" />
        {{ t('cookies.settings') }}
      </button>
    }
  </div>
</form>
