import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { getLangFromRouteParams } from '@manzuko/shared/utils/lang-from-params';
import { getQuantityDiscounts } from '@manzuko/shared/utils/product.helpers';
import { map, switchMap, tap } from 'rxjs';

import { CategoryService } from 'app/data-access/category/category.service';
import {
  productCanonicalRedirect,
  productRemovedRedirect,
  ProductService
} from 'app/data-access/product';
import { RESPONSE } from 'app/ssr/server.token';

import { handleHttpStatus } from '../utils';
import { ProductSeoService } from './product-seo.service';

import type { Response } from 'express';

export const productResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const categoryService = inject(CategoryService);
  const productsService = inject(ProductService);
  const productsSeoService = inject(ProductSeoService);
  const router = inject(Router);
  const response = inject<Response>(RESPONSE, { optional: true });
  const platformId = inject(PLATFORM_ID);

  const language = getLangFromRouteParams(route?.params);
  const productId = +route?.params?.productId;
  const product$ = productsService.getOne({ id: productId, language });

  return product$.pipe(
    tap((product) => handleHttpStatus(product, platformId, response, router)),
    tap((product) => productRemovedRedirect(product, platformId, response, router)),
    tap((product) => productCanonicalRedirect(product, route, platformId, response, router)),
    switchMap((product) =>
      categoryService.get({ id: product?.categoryId, language }).pipe(
        map((categories) => {
          const productCategory = categories?.find(
            (category) => category?.id === product?.categoryId
          );
          productsSeoService.setSeo(product, language);

          // const currentUrl = state?.url;
          // const correctUrl = `${
          //   language === 'en' ? '/en' : ''
          // }/${productCategory?.slug}/${product?.id}-${product?.slug}.html`;

          // if (currentUrl !== correctUrl) {
          //   router.navigateByUrl(correctUrl);
          // }

          return {
            product: {
              ...product,
              quantityDiscounts: getQuantityDiscounts(product, product?.discounts),
              descriptionShort: product?.descriptionShort?.replace(/<\/?h1[^>]*>/g, '')
            },
            language,
            categories,
            productCategory
          };
        })
      )
    )
  );
};
