import { isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { delay, EMPTY, filter, tap } from 'rxjs';

import { AuthActions, selectAuthUser, selectAuthUserIsAdmin } from 'app/data-access/auth';

import { SettingAdminActions } from './admin/data-access/settings/setting.actions';
import { FavoriteActions } from './data-access/favorites';
import { selectSettingShowCookies } from './data-access/setting';
import { CheckForUpdateService } from './sw/check-for-update.sw.service';
import { HandleUnrecoverableStateService } from './sw/handle-unrecoverable-state.sw.service';
import { LogUpdateService } from './sw/log-update.sw.service';
import { PromptUpdateService } from './sw/prompt-update.sw.service';
import { TrustedshopsService } from './vendor/trustedshops.service';

export const APP_COMPONENT_IMPORTS = [RouterModule];

/** Converts all path rules from string to Regex instances */
// function normalizePathRules(rules: Array<string | RegExp>): Array<RegExp> {
//   return rules.map((rule) =>
//     rule instanceof RegExp ? rule : new RegExp(`^${rule.replace('*', '.*')}$`, 'i')
//   );
// }

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  private readonly store = inject(Store);
  // private readonly salesmanago = inject(SalesmanagoService);
  private readonly platformId = inject(PLATFORM_ID);
  // private readonly router = inject(Router);
  // private readonly transloco = inject(TranslocoService);
  private readonly googleAnalytics = inject(GoogleAnalyticsService);
  // private readonly seoService = inject(SEOService);
  // private readonly trafficwatchdogService = inject(TrafficwatchdogService);
  private readonly trustedshopsService = inject(TrustedshopsService);
  // private readonly openReplayService = isPlatformBrowser(this.platformId)
  //   ? inject(OpenReplayService, { optional: true })
  //   : null;

  private readonly isProd = environment.production;
  private readonly isSW = !isPlatformServer(this.platformId) && this.isProd;

  protected readonly log$ = this.isSW ? inject(LogUpdateService)?.updates$ : EMPTY;
  protected readonly check$ = this.isSW
    ? inject(CheckForUpdateService)?.everySixHoursOnceAppIsStable$
    : EMPTY;
  protected readonly prompt$ = this.isSW ? inject(PromptUpdateService)?.updates$ : EMPTY;
  protected readonly recover$ = this.isSW
    ? inject(HandleUnrecoverableStateService)?.recover$
    : EMPTY;
  // protected readonly lang$ = this.store.select(selectRouteLang).pipe(
  //   filter((lang) => lang === 'en'),
  //   tap((lang) => {
  //     this.transloco.setDefaultLang(lang);
  //     this.transloco.setActiveLang(lang);
  //     this.seoService.setDocumentLang(lang);
  //     this.store.dispatch(LayoutActions.setLanguage({ language: lang }));
  //   })
  // );
  protected readonly trustedshops$ = this.store.select(selectAuthUserIsAdmin).pipe(
    filter((isAdmin) => !isAdmin && !isPlatformServer(this.platformId)),
    delay(10000),
    tap(() => this.trustedshopsService.init())
  );
  // protected readonly openreplayUser$ = this.store.select(selectAuthUser).pipe(
  //   filter((user) => user?.id && !isPlatformServer(this.platformId)),
  //   delay(2500),
  //   tap((user) => this.openReplayService?.setUser(user)),
  //   delay(12500),
  //   tap((user) => this.openReplayService?.setUser(user))
  //   // tap((user) => {
  //   //   if (user.id === 2) {
  //   //     this.salesmanago
  //   //       .contactUpsert({
  //   //         contact: {
  //   //           email: 'michal.jastrzebowski@gmail.com',
  //   //           phone: '+48535381448',
  //   //           name: 'Michał Jastrzębowski',
  //   //           externalId: '2',
  //   //           address: {
  //   //             streetAddress: 'Masarska 13/105',
  //   //             zipCode: '31-534',
  //   //             city: 'Kraków',
  //   //             country: 'PL'
  //   //           }
  //   //         },
  //   //         // province: 'małopolskie',
  //   //         // birthday: '19870605',

  //   //         forceOptIn: true,
  //   //         forceOptOut: false,
  //   //         forcePhoneOptIn: false,
  //   //         forcePhoneOptOut: true

  //   //         // tags: ['T_SHIRTS', 'SWEATERS', 'UPSELL']
  //   //       })
  //   //       .subscribe((response) => {
  //   //         console.log('SALESMANAGO RESPONSE', response);
  //   //       });
  //   //   }
  //   // })
  // );
  // protected readonly openreplayCart$ = this.store.select(selectCartId).pipe(
  //   filter((cartId) => cartId && !isPlatformServer(this.platformId)),
  //   delay(2500),
  //   tap((cartId) => this.openReplayService?.setMetaCartId(cartId)),
  //   delay(12500),
  //   tap((cartId) => this.openReplayService?.setMetaCartId(cartId))
  // );
  protected readonly getFavorites$ = this.store.select(selectAuthUser).pipe(
    filter((user) => user?.id && !isPlatformServer(this.platformId)),
    tap(() => this.store.dispatch(FavoriteActions.loadAll()))
  );

  protected readonly gaConsent$ = this.store.select(selectSettingShowCookies).pipe(
    filter((showCookies) => !showCookies && !isPlatformServer(this.platformId)),
    tap(() => {
      try {
        this.googleAnalytics.gtag('consent', 'update', {
          ad_storage: 'granted',
          ad_user_data: 'granted',
          ad_personalization: 'granted',
          analytics_storage: 'granted',
          functionality_storage: 'granted',
          personalization_storage: 'granted',
          security_storage: 'granted'
        });
      } catch (error) {
        console.error('Error while updating GA consent', error);
      }
    })
  );

  // protected readonly googleTagRouter$ = this.router.events.pipe(
  //   filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
  //   skip(1), // Prevent double views on the first trigger (because GA Already send one ping on setup)
  //   // filter((event) =>
  //   //   includeRules.length > 0
  //   //     ? includeRules.some((rule) => rule.test(event.urlAfterRedirects))
  //   //     : true
  //   // ),
  //   // filter((event) =>
  //   //   excludeRules.length > 0
  //   //     ? !excludeRules.some((rule) => rule.test(event.urlAfterRedirects))
  //   //     : true
  //   // ),
  //   tap((event) => this.googleTagService.pageView(event.urlAfterRedirects, undefined))
  // );

  ngOnInit(): void {
    if (this.isProd) {
      try {
        this.googleAnalytics.gtag('consent', 'default', {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'denied'
        });
      } catch (error) {
        console.error('Error while setting GA default consent', error);
      }

      this.googleAnalytics.gtag('config', 'ZXQVNRWD7Q');
      this.googleAnalytics.gtag('config', 'AW-669185184');
      this.googleAnalytics.gtag('config', 'GTM-MKVK5CN');

      // this.googleAnalytics.gtag('config', 'UA-16073336-9');
      // this.googleAnalytics.gtag('config', 'G-WPDLJVYMF9'); // testowe manzuko live

      // if (!isPlatformServer(this.platformId)) {
      //   this.openReplayService?.delayStart();
      //   // this.trafficwatchdogService.delayInit();
      // }
    }

    if (!isPlatformServer(this.platformId)) {
      this.store.dispatch(AuthActions.verifyJwt());
    }
  }

  constructor() {
    // this.store.dispatch(AuthActions.getSecureRoutes());
    this.store.dispatch(SettingAdminActions.loadList());
  }
}
