<mzk-header class="bg-white sticky top-0 z-[1000]">
  <mzk-nav-desktop class="block border-b-neutral-200 border-b-4 md:border-none is-desktop" />
</mzk-header>

<mzk-nav-mobile class="block border-b-neutral-200 border-b-4 md:border-none is-mobile" />

<!-- <div class="min-h-1">
<mzk-loading-bar *ifIsBrowser
  class="static block min-h-1" />
</div> -->
<mzk-banners class="min-h-20 sm:min-h-[150px] md:min-h-[200px]" />

<router-outlet />

<mzk-footer />

@if (isMobile()) {
  <mzk-nav class="block border-b-neutral-200 border-b-4 md:border-none" />
}

<ng-container *ifIsBrowser>
  <mzk-cart [isOpen]="showCart()" [cart]="cart()" (closed)="hideCart()" />

  @if (isMobile()) {
    <mzk-cart-micro class="fixed w-full bottom-[-1px] z-[1000] translate-y-0" />
  }

  @if (showCookies()) {
    <mzk-cookies
      [showAdvanced]="showAdvanced()"
      class="fixed z-[2147483641] opacity-95 bg-black bg-opacity-70 w-full h-full top-0 left-0 transition-all backdrop-blur-[2px]"
      (showAdvancedChange)="showAdvanced.set($event)" />
  }
</ng-container>
